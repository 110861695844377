import React from 'react';

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const Projects = React.lazy(() => import('./views/projects/Projects'));
const Reports = React.lazy(() => import('./views/reports/Reports3'));

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/projects', name: 'Projects', component: Projects },
  { path: '/reports', name: 'Reports', component: Reports },
]

export default routes;
