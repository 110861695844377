import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { BrowserRouter, Link } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { logout } from 'src/containers/TheHeaderTop'

const paswd_length = 12

//= export const loginUser = async (credentials, mode) => {
//= async function loginUser(credentials, mode) {

export const loginUser = async (credentials, mode) => {
//  return fetch('/api/?europa_debug=1&v=1&mode='+mode,{
  return fetch('/api/?v=1&mode='+mode,{
    method: 'POST',
    body: JSON.stringify(credentials)
  })
  .then(response => response.json())
  .then(data => data)
}

function getURL_parameter(name) {
  var regexS = "[\\?&]"+name+"=([^&#]*)"
  var regex = new RegExp( regexS )
  var results = regex.exec( window.location.href )

  if (results) {
    if (results[1]) {
      return results[1];
    }
    else {
      return true
    }
  }
  else {
    //= Now check if key-only parameter
    var regexS = "[\\?&]"+name
    var regex = new RegExp( regexS )
    var keyonly = regex.exec( window.location.href )

    if (keyonly) {
      return true
    }
    else {
      return false
    }
  }
}

export const paswdValidation = (in_paswd, in_paswd_confirm) => {
  let errorMsgs = ""

  if ( in_paswd != in_paswd_confirm ) {
    errorMsgs += "Passwords do not match."
  }
  else if ( in_paswd.length < paswd_length ) {
    errorMsgs += "Password must be at least " + paswd_length + " characters long."
  }
  else if ( in_paswd.replace(/[^0-9]/g, "").length < 1 ) {
    errorMsgs += "Password must contain at least one number."
  }
  else if ( in_paswd.replace(/[^!@#$%^&*_-]/g, "").length < 1 ) {
    errorMsgs += "Password must contain at least one special character."
  }
  else if ( in_paswd.replace(/[^A-Z]/g, "").length < 1 || in_paswd.replace(/[^a-z]/g, "").length < 1 ) {
    errorMsgs += "Password must contain a mixture of upper/lower case letters."
  }
  else {
    return ""
  }

  return errorMsgs
}

export const emailValidation = (in_email) => {
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if ( !in_email || regex.test(in_email) === false ) {
    document.getElementById("loginError").innerHTML = "Invalid credentials."
    document.getElementById("loginError").style.display = "block"
    return false
  }
  else {
    return true
  }
}


export default function Login({ setToken }) {
  const [email, setUserName] = useState()
  const [paswd, setPassword] = useState("")
  const [paswd_confirm, setPassword_confirm] = useState("")
  const [modal, setModal] = useState(false)
  const [modalMsg, setModalMsg] = useState("")
  const [active, set_active] = useState(true);

  const toggle = (item)=> {
    setModal(!modal)
    if(modal) {
      window.location.href = "//" + window.location.host + "/"
      setPassword("") //= useState to force rerender
    }
  }

  useEffect(()=>{
    set_active(!getURL_parameter("inactive"))
  },[])

  const reset_id = getURL_parameter("rid")
  const r1_flag  = getURL_parameter("r1")
  let isFirstReset = ( r1_flag ? true : false )

//  const request_reset = getURL_parameter("request_reset")
//  const reset = getURL_parameter("reset")
//  const confirm_reset = getURL_parameter("confirm_reset")
//  let mode = ( reset ? "reset" : request_reset ? "request_reset" : confirm_reset ? "confirm_reset" : "login" )

  const url = window.location.href
  const first_param = url.substring(url.lastIndexOf("?") + 1).split("&")[0]
  const modes = ["reset", "request_reset", "confirm_reset", "login"]

  //= Don't show reset window if no RID parameter is present
  let mode = ( modes.includes(first_param) ? ( first_param == "reset" ? ( reset_id ? first_param : "login" ) : first_param ) : "login" )

  const handleSubmit = async e => {
    document.getElementById("loginError").style.display = "none"

    e.preventDefault();

    //= First time users do not need to enter their email again, this is pass on from the login screen in the GET
    if (isFirstReset) {
      setUserName(getURL_parameter("id"))
    }

    if ( emailValidation(email) ) {
      let result_paswdValidation = paswdValidation(paswd, paswd_confirm)

      if ( mode == "login" || mode == "request_reset" || (mode == "reset" && result_paswdValidation == "") ) {
        const token = await loginUser({
          email,
          paswd,
          reset_id
        }, mode)

        if (token.hasOwnProperty('first_reset')) {
          let testurl = window.location.href.replace(/[^/]*$/, '') + "?reset"
          window.location.href = testurl + "&r1&rid=" + token.rid + "&id=" + email

          //= If # hash URL parameter, force a reload!
          if (window.location.hash) {
            //= Reload else password shows!
            window.location.reload();
          }
        }
        else if (token.hasOwnProperty('confirm_reset')) {
          window.location.href = "//" + window.location.host + "/?confirm_reset"
          setPassword("") //= useState to force rerender AND prevent showing password!
        }
        else if (token.hasOwnProperty('reset')) {
          if (token.reset) {
            setModalMsg(token.message)
            setModal(!modal);
          }
          else {
            document.getElementById("loginError").innerHTML = token.message
            document.getElementById("loginError").style.display = "block"
          }
        }
        else if (!token.hasOwnProperty('token')) {
          document.getElementById("loginError").innerHTML = "Invalid credentials."
          document.getElementById("loginError").style.display = "block"
        }
        else {
          let group_id = token.token.appData.info[0].group_id
          let end = token.token.appData.end * 1000 //= Server s vs client ms!
          setTimeout(function() { logout("inactive") }, end)

//=########################################################
//=########################################################
/*
let email = token.token.email
if (email == "jlee+demo@europa.uk.com") {
  console.log("SPECIAL: email is:"+email)
  group_id = ""
//  token.token.appData.info[0].name = "viaEuropa Demo"
//  token.token.orgs[0].label = "viaEuropa Demo"
//  console.log("id_list[0].id:"+token.token.appData.id_list["snh"][0].id)
  for (let i in token.token.appData.id_list["snh"]) {
    //console.log("id_list[0].id:"+item.id)
    let tmp_id = token.token.appData.id_list["snh"][i].id
    tmp_id = tmp_id.replace("osmao-snh00-000",  "psgao-demo0-000")
    tmp_id = tmp_id.replace("osmab-snh00-000",  "psgab-demo0-000")
    console.log("tmp_id:"+tmp_id)
    token.token.appData.id_list["snh"][i].id = tmp_id
  }
}
//*/
//=########################################################
//=########################################################


          //= Add globals to token to be used througout the app
          const service_info = (
            email == "jlee+demo@europa.uk.com" ?
            {
              "wmts" : { "colour" : "#e6625e", "mult" : 0.01*1 },
              "vts"  : { "colour" : "#ff9966", "mult" : 0.50*1 },
              "wms"  : { "colour" : "#a1f3c2", "mult" : 1.00*1 },
              "wfs"  : { "colour" : "#fed978", "mult" : 3.00*1 },
              "api"  : { "colour" : "#9f71e3", "mult" : 0.50*1 },
            }
            :
            {
              "wmts" : { "colour" : "#e6625e", "mult" : 0.01 },
              "vts"  : { "colour" : "#ff9966", "mult" : 0.50 },
              "wms"  : { "colour" : "#a1f3c2", "mult" : ( group_id == "osma" ? 0.01 : 1.00 ) },
              "wfs"  : { "colour" : "#fed978", "mult" : 3.00 },
              "api"  : { "colour" : "#9f71e3", "mult" : 0.50 },
            }
          )


          token.token.appData.service_info = service_info
          setToken(token);
        }
      }
      else {
        document.getElementById("loginError").innerHTML = result_paswdValidation
        document.getElementById("loginError").style.display = "block"
      }
    }
    else if (mode == "reset") {
      console.log("reset mode")
    }
    else if (mode == "request_reset") {
      console.log("request_reset mode")
    }
  else {
      if (mode == "confirm_reset") {
        console.log("confirm_reset mode")
      }
    }
  }

  return(
    <>

    <CModal show={modal} onClose={toggle} className="europa_modal">
      <CModalBody> {modalMsg} </CModalBody>
      <CModalFooter>
        <CButton color="primary" onClick={toggle}>OK</CButton>
      </CModalFooter>
    </CModal>

    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardHeader className="europa_login_card_header">

                  <div className="appName_via d-md-flex me-auto">
                    <div className="appName_via">viaEuropa</div>
                    <div className="appName_portal">Portal</div>
                  </div>

                  <div className="viaeuropa_logo"></div>
                </CCardHeader>
                <CCardBody>

{
  { "reset":
                  <CForm onSubmit={handleSubmit}>
    { isFirstReset ?
                      <p className="text-muted">As this is your first access to Portal, you must change your password.</p>
    :
                      <p className="text-muted">Please re-enter your email and a new password for your viaEuropa Portal account.</p>
    }

                    <p className="text-muted">The password must be a minimum of {paswd_length} characters in length with mixed case and at least one numeric and one special character.</p>

    { !isFirstReset &&
                   <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="text" placeholder="Re-enter e-mail" autoComplete="username" onChange={e => setUserName(e.target.value.trim())}/>
                    </CInputGroup>
    }

                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" placeholder="New Password" autoComplete="current-password" onChange={e => setPassword(e.target.value)}/>
                    </CInputGroup>

                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" placeholder="Confirm New Password" autoComplete="current-password" onChange={e => setPassword_confirm(e.target.value)}/>
                    </CInputGroup>

                    <CRow>
                      <CCol xs="6">
                        <CButton type="submit" color="primary" className="px-4" onClick={handleSubmit}>OK</CButton>
                      </CCol>
                    </CRow>

                    <div id='loginError1'>
                      Invalid credentials.
                    </div>
                  </CForm>,
  "request_reset":
                  <CForm onSubmit={handleSubmit}>
                   <p className="text-muted">Enter your email address and click to reset your password:</p>

                   <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="text" placeholder="E-mail" autoComplete="username" onChange={e => setUserName(e.target.value.trim())}/>
                    </CInputGroup>

                    <CRow>
                      <CCol xs="6">
                        <CButton type="submit" color="primary" className="px-4" onClick={handleSubmit}>Reset</CButton>
                      </CCol>
                    </CRow>

                    <div id='loginError2'>
                      Invalid credentials.
                    </div>
                  </CForm>,
  "confirm_reset":
                  <CForm onSubmit={handleSubmit}>
                    <p className="text-muted">
                      A password reset link has been sent to your email.<br/>
                      Please check your email, including the junk folder.
                    </p>
                    <br/>

                    <CRow>
                      <CCol xs="6">
                        <BrowserRouter>
                          <Link to="#">
                            <CButton color="primary" className="px-4" onClick={e => setUserName("")}>Continue</CButton> {/*!!! Use setUserName to force rerender !!!*/}
                          </Link>
                        </BrowserRouter>
                      </CCol>
                    </CRow>

                    <div id='loginError3'>
                      Invalid credentials.
                    </div>
                  </CForm>,
  } [mode] ||
                  <CForm onSubmit={handleSubmit}>
{!active &&
                    <p className="login-text-alert">You were automatically logged out.</p>
}

                    <p className="text-muted">Please sign in to your account:</p>

                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="text" placeholder="E-mail" autoComplete="username" onChange={e => setUserName(e.target.value.trim())}/>
                    </CInputGroup>

                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" placeholder="Password" autoComplete="current-password" onChange={e => setPassword(e.target.value)}/>
                    </CInputGroup>

                    <CRow>
                      <CCol xs="6">
                        <CButton type="submit" color="primary" className="px-4" onClick={handleSubmit}>Login</CButton>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <BrowserRouter>
                          <Link to="?request_reset">
                            <CButton color="link" className="px-0" onClick={e => setUserName("")}>Forgot password?</CButton> {/*!!! Use setUserName to force rerender !!!*/}
                          </Link>
                        </BrowserRouter>
                      </CCol>
                    </CRow>

                    <div id='loginError4'>
                      Invalid credentials.
                    </div>
                  </CForm>
}
                    <div id='loginError'>
                      Invalid credentials.
                    </div>


                </CCardBody>
              </CCard>

            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
    </>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}
