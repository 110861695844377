import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CHeader,
  CToggler,
  CContainer,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CInput,
  CButton,
  CFormGroup,
  CForm,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import useToken from 'src/views/pages/login/useToken'
import { paswdValidation, loginUser  } from 'src/views/pages/login/Login'

// routes config
import routes from '../routes'
import { cibOpenCollective } from '@coreui/icons'

export const fetch_api = async (in_query) => {
  return fetch('/api/?' + in_query, {})
  .then(response => {
    const timeout = response.headers.get(global.config.COMMON_HEADER_PREFIX + "timeout")
    if (timeout == "true") {
      logout("inactive")
    }
    else {
      return response.text()
    }
  })
  .then(data => data)
}

export const logout = (in_inactive) => {
  fetch('/api/?mode=logout&v=1')
    .then(response => response)
    .then(data => {
      localStorage.clear()
      if (in_inactive == "inactive") {
        let testurl = window.location.href.replace(/[^/]*$/, '') + "?inactive"
        window.location.href = testurl

        //= If # hash URL parameter, force a reload!
        if (window.location.hash) {
          //= Reload else password shows!
          window.location.reload();
        }
      }
      else {
        window.location.reload()
      }

      return
    })
}

const TheHeaderTop = () => {

  console.log("TheHeaderTop is loading...")
  fetch_api('')
  .then(data_raw => {
    //= Timeout
  })

  const {token, setToken} = useToken()
  const dispatch = useDispatch()
//  const asideShow = useSelector(state => state.asideShow)
//  const darkMode = useSelector(state => state.darkMode)
  const sidebarShow = useSelector(state => state.sidebarShow)

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    dispatch({type: 'set', sidebarShow: val})
  }

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    dispatch({type: 'set', sidebarShow: val})
  }

  const [email, setEmail] = useState(token.email)
  const [paswd, setPaswd] = useState("")
  const [paswdNew, set_paswdNew] = useState("")
  const [paswdNewConfirm, set_paswdNewConfirm] = useState("")

  const [modal, setModal] = useState(false);

  const [modalMsg, setModalMsg] = useState("");
  const [modalMsgBox, setModalMsgBox] = useState(false);

  const toggle_modalMsgBox = (item)=> {
    setModalMsgBox(!modalMsgBox)
    if(modalMsgBox) {
      setPaswd("") //= useState to force rerender
    }
  }
//  const [modalPwd, setModalPwd] = useState("");
  const [modalPwdBox, setModalPwdBox] = useState(false);

  const toggle_modalPwdBox = (item)=> {
console.log("toggle_modalPwdBox")
    document.getElementById("loginError").style.display = "none"
    if(modalPwdBox) {
      document.getElementById("paswd_form_pwd").value = ""
      document.getElementById("paswd_form_pwd_new").value = ""
      document.getElementById("paswd_form_pwd_cnew").value = ""
    }
    setModalPwdBox(!modalPwdBox)
  }

  const toggle = (item)=> {
    setModal(!modal)
    setModalPwdBox(!modalPwdBox)
    if(modal) {
      setPaswd("") //= useState to force rerender
      set_paswdNew("")
      set_paswdNewConfirm("")
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();

    document.getElementById("loginError").style.display = "none"

    let result_paswdValidation = paswdValidation(paswdNew, paswdNewConfirm)

    if ( result_paswdValidation == "" ) {
      const token = await loginUser({
        email,
        paswd,
        paswdNew,
      }, "reset_paswd")

      if (token.hasOwnProperty('reset')) {
        if (token.reset) {
          setModal(!modal);

          setModalMsg(token.message)
          setModalMsgBox(!modalMsgBox);
        }
        else {
          document.getElementById("loginError").innerHTML = token.message
          document.getElementById("loginError").style.display = "block"
        }
      }
      else {
      }
    }
    else {
      document.getElementById("loginError").innerHTML = result_paswdValidation
      document.getElementById("loginError").style.display = "block"
    }

  }

  return (
    <>
      <CModal show={modalMsgBox} onClose={toggle_modalMsgBox} className="europa_modal">
        <CModalBody> {modalMsg} </CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={toggle_modalMsgBox}>OK</CButton>
        </CModalFooter>
      </CModal>

      <CModal show={modalPwdBox} onClose={toggle_modalPwdBox} className="europa_modal">
        <CForm onSubmit={handleSubmit}>

          <CModalHeader closeButton>
            Change Password
          </CModalHeader>

          <CModalBody>
              <CFormGroup row className="europa_reports_form">

                <CInputGroup className="mb-3">
                  <CInputGroupPrepend>
                    <CInputGroupText>
                      <CIcon name="cil-lock-locked" />
                    </CInputGroupText>
                  </CInputGroupPrepend>
                  <CInput id="paswd_form_pwd" type="password" placeholder="Password" onChange={e => setPaswd(e.target.value)}/>
                </CInputGroup>

                <CInputGroup className="mb-3">
                  <CInputGroupPrepend>
                    <CInputGroupText>
                      <CIcon name="cil-lock-locked" />
                    </CInputGroupText>
                  </CInputGroupPrepend>
                  <CInput id="paswd_form_pwd_new" type="password" placeholder="New Password" onChange={e => set_paswdNew(e.target.value)}/>
                </CInputGroup>

                <CInputGroup className="mb-4">
                  <CInputGroupPrepend>
                    <CInputGroupText>
                      <CIcon name="cil-lock-locked" />
                    </CInputGroupText>
                  </CInputGroupPrepend>
                  <CInput id="paswd_form_pwd_cnew" type="password" placeholder="Confirm New Password" onChange={e => set_paswdNewConfirm(e.target.value)}/>
                </CInputGroup>

              </CFormGroup>

            <div id='loginError'>
              Invalid credentials.
            </div>
          </CModalBody>

          <CModalFooter>
            <CButton type="submit" color="primary" onClick={handleSubmit}>OK</CButton>
            <CButton color="secondary" onClick={toggle_modalPwdBox}>Cancel</CButton>
          </CModalFooter>
        </CForm>
      </CModal>

    <CHeader position="sticky" className='europa_header'>
      <CContainer fluid>

        {/*=====================================================
           = HAMBURGER
           =====================================================*/}
        <CToggler
          inHeader
          className="ml-md-3 d-lg-none viaeuropa_header europa_hamburger"
          onClick={toggleSidebarMobile}
        />
        <CToggler
          inHeader
          className="ml-3 d-md-down-none viaeuropa_header europa_hamburger"
          onClick={toggleSidebar}
        />

{/*
        <CHeaderNav className="d-md-down-none mr-auto">
          <CHeaderNavItem className="px-3" >
            <CHeaderNavLink to="/dashboard">Dashboard</CHeaderNavLink>
          </CHeaderNavItem>
        </CHeaderNav>
*/}

        {/*=====================================================
           = LOGO AND TITLE
           =====================================================*/}
          {/*<div className="appName_via d-md-flex me-auto"></div>*/}
          <div className="appName_via d-sm-down-none me-auto">
            <div className="appName_via" color="colour-europa-reflexblue">viaEuropa</div>
            <div className="appName_portal">Portal</div>
          </div>
          {/*<div className="viaeuropa_logo d-md-down-none mr-auto"></div>*/}
          <div className="viaeuropa_logo d-md-flex me-auto"></div>

        {/*=====================================================
           = DROPDOWN
           =====================================================*/}
        <CDropdown className="mt-1 portal-dd europa_dropdown">
          <CDropdownToggle caret color="colour-europa50-pantone320"  data-coreui-toggle="tooltip" title={token.email} >
            <CIcon name="cil-user" />
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem onClick={toggle}>
              <CIcon name="cil-settings" className="mfe-2" />
              Change Password
            </CDropdownItem>
            <CDropdownItem onClick={logout}>
              <CIcon name="cil-LockLocked" className="mfe-2" />
              Logout
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>

{/*
        <CToggler
          inHeader
          className="ml-md-3 d-lg-none"
          onClick={toggleSidebarMobile}
        />

        <CToggler
          inHeader
          className="ml-3 d-md-down-none"
          onClick={toggleSidebar}
        />

        <CHeaderBrand className="mx-auto d-lg-none" to="/">
          <CIcon name="logo" height="48" alt="Logo"/>
        </CHeaderBrand>

        <CHeaderNav className="px-3">
          <CToggler
            inHeader
            className="ml-3 d-md-down-none c-d-legacy-none"
            onClick={() => dispatch({type: 'set', darkMode: !darkMode})}
            title="Toggle Light/Dark Mode"
          >
            <CIcon name="cil-moon" className="c-d-dark-none" alt="CoreUI Icons Moon" />
            <CIcon name="cil-sun" className="c-d-default-none" alt="CoreUI Icons Sun" />
          </CToggler>
        </CHeaderNav>
*/}
      </CContainer>

    </CHeader>
    </>
  )
}

export default TheHeaderTop
