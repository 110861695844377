import { useState } from 'react';

//= Alt style: export default function useToken() {
function useToken() {
  //==============================================================
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);

    if (userToken) {
      return userToken.token
    }
    else {
      return null
    }
  };
  //==============================================================

  //==============================================================
  const [token, setToken] = useState(getToken());
  //==============================================================

  //==============================================================
  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
  };
  //==============================================================

  return {
    setToken: saveToken, token
  }
}

export default useToken